<template>
  <div>
    <validation-observer ref="form">
      <div class="row">
        <div class="col-12 pb-0">
          <h3>Tipo de actividad</h3>
          <p class="body-2">Puedes seleccionar como máximo tres tipos diferentes de actividad pero debes seleccionar al
            menos una.</p>
          <validation-provider
            v-slot="{ errors }"
            name="Tipo de actividad"
            vid="companyActivityTypes"
            :rules="{minSize: 1, maxSize: 3}"
          >
            <v-checkbox
              class="ml-2"
              v-for="(activityType, index) in activityTypes"
              multiple
              :rules="errors"
              color="primary"
              :hide-details="index !== activityTypes.length - 1"
              v-model="model.companyActivityTypes"
              :key="activityType.id"
              :value="activityType.id"
              :label="activityType.name"/>
          </validation-provider>
        </div>
        <div class="col-6 pt-0 pb-0" v-if="showCompanyActivityTypeDescription">
          <validation-provider
            v-slot="{ errors }"
            vid="companyActivityTypeDescription"
            name="¿Cúal?"
            rules="required"
          >
            <v-text-field
              v-model="model.companyActivityTypeDescription"
              class="pt-0 pl-2"
              type="text"
              outlined
              dense
              :error-messages="errors"
              label="¿Cúal?"
              required
            />
          </validation-provider>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h3>Categorías y segmentos</h3>
          <p class="body-2">Selecciona las categorías y de cada una de ellas los segmentos a los que corresponden.</p>
        </div>
        <div class="col-12 col-md-6">
          <p class="font-bold pb-0 mb-0">Categorías*</p>
          <p class="body-2">Puedes seleccionar como máximo tres categorías pero debes seleccionar al menos una.</p>

          <validation-provider
            v-slot="{ errors }"
            name="Categorias"
            vid="companyCategories"
            :rules="{minSize: 1, maxSize: 3}"
          >
            <v-checkbox
              v-for="(category, index) in categories"
              multiple
              class="ml-2"
              color="primary"
              :hide-details="index !== categories.length - 1"
              v-model="model.companyCategories"
              :key="category.id"
              :value="category.id"
              :label="category.name"
              :rules="errors"/>
          </validation-provider>
        </div>
        <div class="col-12 col-md-6">
          <p class="font-bold pb-0 mb-0">Segmentos*</p>
          <p class="body-2">Puedes seleccionar como máximo 10 segmentos por categoría pero debes seleccionar al menos
            uno.</p>
          <div class="row" v-for="(category, indexCategory) in categories" :key="'cat-' + category.id">
            <div v-if="showSegment(category.id)">
              <validation-provider
                v-if="category.segments.length"
                v-slot="{ errors }"
                name="Segmento"
                :vid="'companySegments' + category.id"
                :rules="{minSize: 1, maxSize: 10}"
              >
                <div class="col-12 pb-0" v-if="category.segments.length">
                  <h4>{{ category.name }}</h4>
                </div>
                <div class="col-12 mx-2 pt-0 pb-0">
                  <v-checkbox
                    v-for="(segment, index) in category.segments"
                    :key="'seg-' + segment.id"
                    multiple
                    color="primary"
                    :hide-details="index !== category.segments.length - 1"
                    v-model="model.companySegments[indexCategory]"
                    :value="segment.id"
                    :error-messages="errors"
                    :label="segment.name"/>
                </div>
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="col-6 pt-0 pb-0" v-if="showCompanyCategoryDescription">
          <validation-provider
            v-slot="{ errors }"
            vid="showCompanyCategoryDescription"
            name="¿Cúal?"
            rules="required"
          >
            <v-text-field
              v-model="model.companyCategoryDescription"
              class="pt-0 pl-2"
              type="text"
              outlined
              dense
              :error-messages="errors"
              label="¿Cúal?"
              required
            />
          </validation-provider>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-0 pb-0">
          <p class="body-2">A continuación agrega hasta 10 marcas que tu empresa exhibirá en la Feria. Para agregar
            una marca escríbela en el cuadro inferior y haz clic en el botón "Agregar". Para eliminar o editar una
            marca, haz clic sobre ella en la lista.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="font-bold">Marcas que exhibirá la empresa (máximo 10).</p>
          <div class="row" v-for="(companyBrand, index) in model.companyBrands" :key="index">
            <div class="col-8 col-md-6 pt-0 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="Nombre marca"
                :vid="'companyBrand'+index"
                rules="required|max:255"
              >
                <v-text-field
                  v-model="companyBrand.name"
                  type="text"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Nombre marca"
                  required
                ></v-text-field>
              </validation-provider>
            </div>
            <div v-if="index !== 0" class="col-4 col-md-1 pt-0 pb-0 pr-1">
              <v-btn color="primary" class="mr-2" @click="deleteBrand(index)">
                <v-icon
                  center
                  dark
                  size="15">
                  fa-trash
                </v-icon>
              </v-btn>
            </div>
          </div>

          <div class="row">
            <div class="col-12 pt-0">
              <v-btn color="secondary" class="mr-2 text-none elevation-0" @click="addBrand">
                Agregar
              </v-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep4">
                <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
                <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
              </v-btn>
              <v-btn color="default" class="mr-2 text-none elevation-0" @click="$emit('nextStep',3)">
                Anterior
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>

import { ACTIVITY_TYPES_URL, BOOKINGS_URL, CATEGORIES_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import sessionMixin from '@/mixins/sessionMixin'

export default {
  created () {
    eventHub.$on('step4', async () => {
      try {
        this.showLoader()
        await Promise.all([this.getActivityTypes(), this.getCategories()])
        await this.getStep4()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  data () {
    return {
      activityTypes: [],
      categories: [],
      showCompanyActivityTypeDescription: false,
      showCompanyCategoryDescription: false,
      model: {
        companyActivityTypeDescription: null,
        companyActivityTypes: [],
        companyCategories: [],
        companyCategoryDescription: null,
        companySegments: [],
        companyBrands: [{}]
      }
    }
  },
  methods: {
    addBrand () {
      if (this.model.companyBrands.length < 10) {
        this.model.companyBrands.push({})
      }
    },
    deleteBrand (position) {
      this.model.companyBrands = this.model.companyBrands.filter((item, index) => index !== position)
    },
    async getActivityTypes () {
      const response = await this.get(ACTIVITY_TYPES_URL)
      this.activityTypes = response.data
    },
    async getCategories () {
      const response = await this.get(CATEGORIES_URL)
      this.categories = response.data
      await this.categories.forEach(category => {
        category.companySegments = []
      })
    },
    async getStep4 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-4')
      this.model.companyActivityTypes = response.data.activity_types.map(item => {
        if (item.name === 'Otra') {
          this.model.companyActivityTypeDescription = item.pivot.description
        }
        return item.id
      })
      this.model.companyCategories = response.data.categories.map(item => {
        if (item.name === 'Otro') {
          this.model.companyCategoryDescription = item.pivot.description
        }
        return item.id
      })
      const segments = []
      this.categories.forEach((category, index) => {
        segments[index] = []
        category.segments.forEach(segment => {
          response.data.segments.forEach(item => {
            if (item.id === segment.id) {
              segments[index].push(item.id)
            }
          })
        })
      })
      this.model.companySegments = segments
      this.model.companyBrands = (response.data.brands.length > 0) ? response.data.brands.map(item => {
        return {
          name: item.name
        }
      }) : [{}]
    },
    saveStep4 () {
      this.executeWithFormValidation(() => {
        const model = { ...this.model }
        model.companySegments = []
        this.model.companySegments.forEach((item) => {
          model.companySegments.push(...item)
        })
        this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-4', model).then(() => {
          this.$emit('nextStep', 5)
        })
      })
    },
    showSegment (categoryId) {
      const exist = this.model.companyCategories.find(item => item === categoryId)
      return !!exist
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin, formOperationsMixin, sessionMixin],
  name: 'EconomicActivity',
  props: {
    bookingId: {
      default: null,
      type: String
    }
  },
  watch: {
    'model.companyActivityTypes' () {
      this.showCompanyActivityTypeDescription = false
      this.model.companyActivityTypes.forEach((companyActivityType) => {
        const showCompanyActivityTypeDescription = this.activityTypes.filter(item => item.id === companyActivityType)
        if (showCompanyActivityTypeDescription[0].name === 'Otra') {
          this.showCompanyActivityTypeDescription = true
        }
      })
    },
    'model.companyCategories' () {
      this.showCompanyCategoryDescription = false
      this.model.companyCategories.forEach((companyCategory) => {
        const showCompanyCategoryDescription = this.categories.filter(item => item.id === companyCategory)
        if (showCompanyCategoryDescription[0].name === 'Otro') {
          this.showCompanyCategoryDescription = true
        }
      })
    }
  }
}
</script>
